import { appWithTranslation } from "@core/i18n/appWithTranslation";
import type { AppProps } from "next/app";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../styles/globals.css";
import "@core/common/components/calendar/customStyles.css";
import "@lib/dayjs/importLanguages";
import * as dayjs from "dayjs";
import { defaultLocale } from "@core/i18n/i18n";
import Head from "next/head";
import { NotificationContainer } from "@lib/notification/NotificationContainer";
import NextNProgress from "nextjs-progressbar";
import { theme } from "@core/theme";

function MyApp({ Component, pageProps }: AppProps) {
  dayjs.locale(defaultLocale);
  return (
    <>
      <Head>
        <title>Altiplano Open Research</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <meta name="theme-color" content="rgb(55, 123, 198)" />
        {process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        {/* That disable Safari iOS zoom on input field */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <NextNProgress color={theme.colors.primary["400"]} options={{ showSpinner: false }} />
      <NotificationContainer />
      <Component {...pageProps} />
    </>
  );
}

export default appWithTranslation(MyApp);
