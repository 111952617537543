/*
 * XXX This file is loaded by `next.config.js and next-18next.config.js`.
 *     It should only contain commonjs code.
 */

const dayjs = require("dayjs");

const defaultLocale = "en";

const supportedLocale = ["en"];

module.exports = {
  serializeConfig: false,
  defaultLocale,
  supportedLocale,
  interpolation: {
    format: function (value, format, lng) {
      if (value instanceof Date || value instanceof dayjs) {
        return dayjs(value).format(format);
      }
      return value;
    },
  },
};
