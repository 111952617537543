import React from "react";
import { ToastContainer, TypeOptions, Zoom } from "react-toastify";
import { ReactComponent as CloseIcon } from "@ui-kit/components/icons/svg/close.svg";
import styles from "./NotificationContainer.module.css";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@ui-kit/components/buttons/Button";

export const NotificationContainer = () => {
  return (
    <ToastContainer
      hideProgressBar={true}
      pauseOnHover={true}
      rtl={false}
      limit={3}
      position={"top-center"}
      icon={false}
      transition={Zoom}
      className={styles.toastContainer}
      bodyClassName={styles.bodyToast}
      toastClassName={(context) =>
        `p-2 xl:p-4 mb-2 xl:rounded-lg bodySmallText bg-${context?.type ?? "default"}-light text-${
          context?.type ?? "default"
        }-main flex flex-row items-center`
      }
      closeButton={CloseButton}
    />
  );
};

const CloseButton = ({ type, closeToast }: { type: TypeOptions; closeToast: () => void }) => (
  <Button
    bgColor="transparent"
    textColor={`text-${type}-main`}
    onClick={closeToast}
    icon={<CloseIcon className={`icon text-base`} />}
  />
);
