/*
 * XXX Do not forget to update `./i18nConfig.js`
 */

export const supportedLocales = ["en"] as const;
export type SupportedLocale = typeof supportedLocales[number];

export const defaultLocale: SupportedLocale = "en";

export const namespaces = [
  "common",
  "home",
  "dayjs",
  "about-us",
  "analyst",
  "analysts",
  "analysis",
  "sign-up",
  "sign-in",
  "dashboard",
  "my-analyses",
  "billings",
  "forgot-password",
  "reset-password",
  "analyses",
  "profile",
  "my-calendar",
  "error",
  "meeting-booking",
  "editorial-notes",
  "legal-files",
  "qalis",
  "academic-notes",
  "newsfeed",
] as const;
export type Namespace = typeof namespaces[number];

const locale = typeof window === "undefined" ? undefined : window.navigator.language;

export const currencyFormat = new Intl.NumberFormat(locale, {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
});

export const currencyFormatWithDecimals = new Intl.NumberFormat(locale, {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
});

export const printCurrencyFormat = (value: number) => {
  const currencyWithoutSign = currencyFormat.format(value).replace("€", "");
  return `€${currencyWithoutSign}`;
};

export const printCurrencyFormatWithDecimal = (value: number) => {
  const currencyWithoutSign = currencyFormatWithDecimals.format(value).replace("€", "");
  return `€${currencyWithoutSign}`;
};
